import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShortArticleCardComponent } from './short-article-card.component';
import { MatCardModule } from '@angular/material/card';
import {ClipboardModule} from '@angular/cdk/clipboard';

@NgModule({
  declarations: [
    ShortArticleCardComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    ClipboardModule
  ],
  exports: [
    ShortArticleCardComponent
  ]
})
export class ShortArticleCardModule { }
