import { Component } from '@angular/core';
import { LangVariables } from '@shared/i18n';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = LangVariables.ru.title;
}
