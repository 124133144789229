import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoadingSpinnerModule } from '@shared/components';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UniInterceptor, BaseApiModule } from '@shared/services';
import { NotificationModule } from '@shared/components/ui/notification-component/notification.module';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
registerLocaleData(localeRu);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BaseApiModule.forRoot(),
    LoadingSpinnerModule,
    NotificationModule,
    BrowserAnimationsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UniInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID, useValue: 'ru-RU'
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
