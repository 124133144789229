import {environment} from '@env/environment.prod';

const api = 'https://uni-dict.com/api';
const apiv2 = 'https://uni-dict.com/apiv2';
// const api = 'http://localhost:4200/api';
// const apiv2 = 'http://localhost:4200/apiv2';

const articles = api + '/articles/';
const experts = api + '/specialist/';
// const articles = '/articles';
const articlesWithId = (articlesId: string) => `${articles}/${articlesId}`;
const articlesWithIdV2 = (articlesId: string) => `${apiv2}/articles/${articlesId}`;

export const SERVER_POINTS = {
  uni: {
    get_all_articles: articles,
    create_article: articles,
    // search_article: articles + '/search',
    search_article: `${apiv2}/articles/search`,
    delete_article: articlesWithId,
    // get_article_by_id: articlesWithId,
    get_article_by_id: articlesWithIdV2,
    edit_article_by_id: articlesWithId,
    most_often_search_article: apiv2 + '/articles/most_often',
    get_experts: experts,
    get_total_views: `${apiv2}/articles/total_views`,
  },
};
