import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { INotificationModel } from './notification-model';

@Component({
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NotificationComponent {

    constructor(private snackBarRef: MatSnackBarRef<NotificationComponent>,
                @Inject(MAT_SNACK_BAR_DATA) public data: INotificationModel) {
    }

    closeWithInfo(): void {
      this.snackBarRef.dismissWithAction();
    }

    closeNotification(): void {
      this.snackBarRef.dismiss();
    }
}
