<mat-card (click)='showFullArticle()'>
  <mat-card-title>
     <div class="title_row">
        <span>{{articleData.en.header}}</span>
        <i class="i" (click)="copyArticle($event)"></i>
     </div>
     <div class="title_row">
      <span>{{articleData.ru.header}}</span>
     </div>
  </mat-card-title>
  <mat-card-content class="article_card_main">
    <div class="article_short_text">
      {{articleData.en.definition}}
    </div>
  </mat-card-content>
</mat-card>
