<mat-card class="offers_card_block">
  <mat-card-content>
    <p class="mb-8">
      Хотите предложить свой термин или нашли ошибку?
    </p>
    <p>
      Напишите нам на почту
      <a href="">
        unidictionary@gmail.com
      </a>
    </p>
    <p class="vk-link">
      <!-- <span>или куратору проекта</span> -->
      или куратору проекта
        <span>
          <img  src="assets/img/Rectangle 11@2x.png"
                class="vk_contact_icon"
                alt="">
          <a href="https://vk.com/renatalukmanova" target="blank">
            Ренате Лукмановой в ВК
          </a>
        </span>
    </p>
  </mat-card-content>
</mat-card>
