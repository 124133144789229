import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NotificationComponent } from './notification.component';
import { NotificationService } from './notification.service';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule, } from '@angular/material/snack-bar';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [
    NotificationComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule
  ],
  providers: [
    NotificationService
  ],
  entryComponents: [
    NotificationComponent
  ]
})

export class NotificationModule { }
