<div class="experts-block-main">
    <div class="experts-block-title">
        Эксперты проекта
    </div>

    <div class="experts-block-list">
            <app-expert-card [ngClass]='{"tot": showExperts?.length < 2}'
                *ngFor='let exp of showExperts; let i = index; trackBy: trackByFunc'
                [item]='exp'></app-expert-card>
    </div>

    <div class="experts-block-show-more"
        (click)='showMoreExperts()'
         *ngIf='expertsFullArr?.length > 6 && expertsFullArr?.length !== showExperts?.length'>
        <div class="experts-block-show-more-text">Показать всех экспертов</div>
    </div>
</div>
