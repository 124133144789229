import { NgModule } from "@angular/core"
import { CommonModule } from '@angular/common'
import { LoadingSpinnerComponent } from "./loading-spinner.component";
import { LoadingSpinnerService } from "./loading-spinner.service";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@NgModule({
  declarations: [LoadingSpinnerComponent],
  imports: [MatProgressSpinnerModule, CommonModule],
  providers: [LoadingSpinnerService],
  exports: [LoadingSpinnerComponent]
})


export class LoadingSpinnerModule {}