import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationComponent } from './notification.component';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable()
export class NotificationService {

  constructor(private snackBar: MatSnackBar) {}

  private defaultMatSnackBarConfig: MatSnackBarConfig = {
      verticalPosition: 'bottom',
      horizontalPosition: 'center'
  };

  // private defaultDuration = 0;
  private defaultDuration = 3000;

  showCopyGreenInfo(message: string, withInformation?: boolean, infoButtonTitle?: string, closeButtonTitle?: string): Observable<void> {

    const snkRef = this.snackBar.openFromComponent(NotificationComponent, {
      ...this.defaultMatSnackBarConfig,
      panelClass: 'green_type_info',
      duration: withInformation ? null : this.defaultDuration,
      data: {
        message,
        infoButtonTitle: infoButtonTitle || null,
        closeButtonTitle: closeButtonTitle || null,
        withInformation: withInformation || false,
        withoutButtons: true
      }
    });

    return snkRef.onAction();
  }

  showCopyWhiteInfo(message: string, withInformation?: boolean, infoButtonTitle?: string, closeButtonTitle?: string): Observable<void> {

    const snkRef = this.snackBar.openFromComponent(NotificationComponent, {
      ...this.defaultMatSnackBarConfig,
      panelClass: 'white_type_info',
      duration: withInformation ? null : this.defaultDuration,
      data: {
        message,
        infoButtonTitle: infoButtonTitle || null,
        closeButtonTitle: closeButtonTitle || null,
        withInformation: withInformation || false,
        withoutButtons: true
      }
    });

    return snkRef.onAction();
  }

}
