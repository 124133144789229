import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-uni-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})

export class FooterComponent {
  today = new Date();

  @Input() isBw = false;

  get url(): string {
    console.log(this.isBw);
    return this.isBw ? 'assets/img/Logo-Uni-BW.png' : 'assets/img/Logo-Uni.svg';
  }
}
