import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ExpertCardModalComponent } from '@shared/components/expert-card-modal/expert-card-modal.component';
import { IExpertInfo } from '@shared/models';
import { Observable } from 'rxjs';

@Injectable()
export class ModalDialogService {

  constructor(private dialog: MatDialog) {
  }

  openExpertWindow(expertInfo: IExpertInfo): Observable<any> {
    const dialogCfg: MatDialogConfig = {
      data: expertInfo,
      panelClass: 'expert-modal-class',
      backdropClass: 'expert-modal-backdrop-class'
    };
    const dialogRef = this.dialog.open(ExpertCardModalComponent, dialogCfg);

    return dialogRef.afterClosed();
  }
}
