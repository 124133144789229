import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { IArticle } from '@shared/models';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationService } from '../ui/notification-component/notification.service';
import { Router } from '@angular/router';
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'short-article-card',
  templateUrl: './short-article-card.component.html',
  styleUrls: ['./short-article-card.component.scss']
})

export class ShortArticleCardComponent implements OnInit {

  @Input() articleData: IArticle;
  @Output() shortArticleCardClicked = new EventEmitter();

  constructor(private clipboard: Clipboard,
              private router: Router,
              private noty: NotificationService) { }

  ngOnInit(): void {
  }

  showFullArticle(): void {
    /* const url = this.router.createUrlTree(['full-article/' + this.articleData.id]);
    window.open(url.toString(), '_blank'); */
    this.shortArticleCardClicked.emit();
    this.router.navigate(['full-article/' + this.articleData.id]);
  }

  copyArticle(event): void {
    this.clipboard.copy(`${this.articleData.en.header} - ${this.articleData.ru.header}`);
    this.noty.showCopyWhiteInfo('Текст скопирован');
    event.stopPropagation();
  }

}
