import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IExpertInfo } from '@shared/models';
import { trackByFunc } from '@shared/functions-tools';

@Component({
  selector: 'app-experts-block',
  templateUrl: './experts-block.component.html',
  styleUrls: ['./experts-block.component.scss']
})
export class ExpertsBlockComponent implements OnInit {

  //#region mockVariables
  expertMockArr = [
    {
      id: '1',
      FirstName: 'Светлана1',
      MiddleName: 'Евгеньевна1',
      SecondName: 'Родионова1',
      FullName: 'Родионова1 Светлана1 Евгеньевна1',
      Description: [' разработчик 3-х поколений государственных образовательных стандартов, в том числе новой версии федерального государственного',
                    ' образовательного стандарта (ФГОС 3++), а также примерных основных образовательных программ (ПООП) по направлению подготовки ВО «Филология»;',
                    ' автор работ, посвященных проблемам реформирования высшей школы и опубликованных в авторитетных научных журналах, рецензируемых ВАК РФ и индексируемых в БД Web of science  и Scopus.'],
      PhotoUri: 'http://uni-dict.com/RSE_1.jpg'
    },
    {
      id: '2',
      FirstName: 'Светлана2',
      MiddleName: 'Евгеньевна2',
      SecondName: 'Родионова2',
      FullName: 'Родионова2 Светлана2 Евгеньевна2',
      Description: ['Родионова Светлана Евгеньевна – кандидат филологических наук, доцент, эксперт в области',
                    ' образовательного стандарта (ФГОС 3++), а также примерных основных образовательных программ (ПООП) по направлению подготовки ВО «Филология»;',
                    ' автор работ, посвященных проблемам реформирования высшей школы и опубликованных в авторитетных научных журналах, рецензируемых ВАК РФ и индексируемых в БД Web of science  и Scopus.'],
      PhotoUri: 'http://uni-dict.com/RSE_1.jpg'
    },
    {
      id: '3',
      FirstName: 'Светлана3',
      MiddleName: 'Евгеньевна3',
      SecondName: 'Родионова3',
      FullName: 'Родионова3 Светлана3 Евгеньевна3',
      Description: ['Родионова Светлана Евгеньевна – кандидат филологических наук, доцент, эксперт в области',
                    'учебно-методической работы в вузе в условиях перехода на ФГОС ВО, член Федерального'],
      PhotoUri: 'http://uni-dict.com/RSE_1.jpg'
    },
    {
      id: '4',
      FirstName: 'Светлана4',
      MiddleName: 'Евгеньевна4',
      SecondName: 'Родионова4',
      FullName: 'Родионова4 Светлана4 Евгеньевна4',
      Description: ['Родионова Светлана Евгеньевна – кандидат филологических наук, доцент, эксперт в области',
                    'учебно-методической работы в вузе в условиях перехода на ФГОС ВО, член Федерального',
                    'учебно-методического объединения (ФУМО) по укрупненной группы специальностей и направлений ВО (УГСН) «Языкознание и литературоведение»,',
                    ' автор работ, посвященных проблемам реформирования высшей школы и опубликованных в',
                    ' авторитетных научных журналах, рецензируемых ВАК РФ и индексируемых в БД Web of science  и Scopus.'],
      PhotoUri: 'http://uni-dict.com/RSE_1.jpg'
    },
    {
      id: '5',
      FirstName: 'Светлана5',
      MiddleName: 'Евгеньевна5',
      SecondName: 'Родионова5',
      FullName: 'Родионова Светлана Евгеньевна5',
      Description: ['Родионова Светлана Евгеньевна – кандидат филологических наук, доцент, эксперт в области',
                    'учебно-методической работы в вузе в условиях перехода на ФГОС ВО, член Федерального',
                    'учебно-методического объединения (ФУМО) по укрупненной группы специальностей и направлений ВО (УГСН) «Языкознание и литературоведение»,',
                    ' разработчик 3-х поколений государственных образовательных стандартов, в том числе новой версии федерального государственного',
                    ' образовательного стандарта (ФГОС 3++), а также примерных основных образовательных программ (ПООП) по направлению подготовки ВО «Филология»;',
                    ' автор работ, посвященных проблемам реформирования высшей школы и опубликованных в авторитетных научных журналах, рецензируемых ВАК РФ и индексируемых в БД Web of science  и Scopus.'],
      PhotoUri: 'http://uni-dict.com/RSE_1.jpg'
    },
    {
      id: '6',
      FirstName: 'Светлана6',
      MiddleName: 'Евгеньевна6',
      SecondName: 'Родионова6',
      FullName: 'Родионова Светлана Евгеньевна5',
      Description: ['Родионова Светлана Евгеньевна – кандидат филологических наук, доцент, эксперт в области',
                    'учебно-методической работы в вузе в условиях перехода на ФГОС ВО, член Федерального',
                    'учебно-методического объединения (ФУМО) по укрупненной группы специальностей и направлений ВО (УГСН) «Языкознание и литературоведение»,',
                    ' разработчик 3-х поколений государственных образовательных стандартов, в том числе новой версии федерального государственного',
                    ' образовательного стандарта (ФГОС 3++), а также примерных основных образовательных программ (ПООП) по направлению подготовки ВО «Филология»;',
                    ' автор работ, посвященных проблемам реформирования высшей школы и опубликованных в авторитетных научных журналах, рецензируемых ВАК РФ и индексируемых в БД Web of science  и Scopus.'],
      PhotoUri: 'http://uni-dict.com/RSE_1.jpg'
    },
    {
      id: '5',
      FirstName: 'Светлана7',
      MiddleName: 'Евгеньевна7',
      SecondName: 'Родионова7',
      FullName: 'Родионова Светлана Евгеньевна5',
      Description: ['Родионова Светлана Евгеньевна – кандидат филологических наук, доцент, эксперт в области',
                    'учебно-методической работы в вузе в условиях перехода на ФГОС ВО, член Федерального',
                    'учебно-методического объединения (ФУМО) по укрупненной группы специальностей и направлений ВО (УГСН) «Языкознание и литературоведение»,',
                    ' разработчик 3-х поколений государственных образовательных стандартов, в том числе новой версии федерального государственного',
                    ' образовательного стандарта (ФГОС 3++), а также примерных основных образовательных программ (ПООП) по направлению подготовки ВО «Филология»;',
                    ' автор работ, посвященных проблемам реформирования высшей школы и опубликованных в авторитетных научных журналах, рецензируемых ВАК РФ и индексируемых в БД Web of science  и Scopus.'],
      PhotoUri: 'http://uni-dict.com/RSE_1.jpg'
    },
  ];

  expertMockArr2 = [
    {
      id: '1',
      FirstName: 'Светлана',
      MiddleName: 'Евгеньевна',
      SecondName: 'Родионова',
      FullName: 'Родионова Светлана Евгеньевна',
      Description: ['Родионова Светлана Евгеньевна – кандидат филологических наук, доцент, эксперт в области',
                    'учебно-методической работы в вузе в условиях перехода на ФГОС ВО, член Федерального',
                    'учебно-методического объединения (ФУМО) по укрупненной группы специальностей и направлений ВО (УГСН) «Языкознание и литературоведение»,',
                    ' разработчик 3-х поколений государственных образовательных стандартов, в том числе новой версии федерального государственного',
                    ' образовательного стандарта (ФГОС 3++), а также примерных основных образовательных программ (ПООП) по направлению подготовки ВО «Филология»;',
                    ' автор работ, посвященных проблемам реформирования высшей школы и опубликованных в авторитетных научных журналах, рецензируемых ВАК РФ и индексируемых в БД Web of science  и Scopus.'],
      PhotoUri: 'http://uni-dict.com/RSE_1.jpg'
    }
  ];
  //#endregion

  expertsFullArr: IExpertInfo[] = [];
  showExperts: IExpertInfo[] = [];

  @Input() set experts(exp: IExpertInfo []) {
    // exp = this.expertMockArr;
    // console.log('Experts: ', exp);
    this.expertsFullArr = exp;
    this.showExperts = exp?.length > 6 ? exp.slice(0, 6) : exp;
  }

  trackByFunc = trackByFunc;

  constructor(
  ) { }

  ngOnInit(): void { }

  showMoreExperts(): void {
    this.showExperts = this.expertsFullArr;
  }

}
