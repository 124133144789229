import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { NotificationService } from '@shared/components/ui/notification-component/notification.service';
import { BaseApiService } from './base-api-service/base-api.service';
import { IExpertInfo, IArticle, SERVER_POINTS } from '@shared/models';

@Injectable({providedIn: 'root'})
export class ArticleService {

  constructor(private api: BaseApiService,
              private noty: NotificationService) {}

  findPhrase(textForSearch: string): Observable<IArticle[]> {
    // const params = new HttpParams();
    // .set('to_search', textForSearch);
    // const headers: HttpHeaders = new HttpHeaders()
    // .set('X-Fields', 'id, EnglishPart{Header,Definition},RussianPart{Header,Definition}');
    // return this.api.getWithParams(SERVER_POINTS.uni.search_article, params, headers);

    return this.api.get(`${SERVER_POINTS.uni.search_article}/${textForSearch}`);
  }

  getFullArticleById(articleId: string): Observable<IArticle> {
    return this.api.get(SERVER_POINTS.uni.get_article_by_id(articleId));
  }

  getMostOftenSearchArticle(): Observable<IArticle[]> {
    return this.api.get(SERVER_POINTS.uni.most_often_search_article);
  }

  getExperts(): Observable<IExpertInfo[]> {
    return this.api.get(SERVER_POINTS.uni.get_experts);
  }

  getTotalViews(): Observable<number> {
    return this.api.get(SERVER_POINTS.uni.get_total_views);
  }
}
