import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpertCardComponent } from './expert-card.component';
import { ModalDialogServiceModule } from '@shared/services';

@NgModule({
  declarations: [ExpertCardComponent],
  imports: [
    CommonModule,
    ModalDialogServiceModule
  ],
  exports: [
    ExpertCardComponent
  ]
})

export class ExpertCardModule { }
