<div class="expert-modal-card-title">
  <div class="expert-modal-card-foto">
    <div class="ellipse2">
      <div class="ellipse1">
        <img src="{{data?.PhotoUri}}" alt="expert-foto">
      </div>
    </div>
  </div>

  <div class="expert-modal-card-fullName">
      <!-- <div>{{data?.surname}} {{data?.firstName}}</div>
      <div>{{data?.secondName}}</div> -->
      <div>{{data?.FullName}}</div>
  </div>

  <img  class="expert-modal-close"
        src="assets/img/Icons_32_Close.svg"
        (click)="closeModal()"
        alt=""
  >
</div>

<div class="expert-modal-card-description">
  <div class="description-text">
    <p *ngFor='let desc of data?.Description; let i = index; trackBy: trackByFunc'>
      {{desc}}
    </p>
  </div>
</div>

