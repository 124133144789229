<div class="footer_component_main_block">
  <a routerLink="/">
  <img [src]="url"
       class="uni_logo"
       alt="">
  </a>
  <div class="uni_footer_text">
    Терминологический словарь тезаурусного типа <br>
    Сфера устойчивого развития природных территорий <br>
    Сфера высшего образования <br>
    © УУНиТ {{ today.getFullYear() }} © Лукманова Р.Р.
  </div>
</div>
