import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalDialogService } from './modal-dialog.service';
import { ExpertCardModalModule } from '@shared/components/expert-card-modal/expert-card-modal.module';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    ExpertCardModalModule
  ],
  providers: [
    ModalDialogService
  ]
})
export class ModalDialogServiceModule { }
