import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class UniInterceptor implements HttpInterceptor {

  constructor(private router: Router ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap(
      (request) => {
        // console.log('interceptReq: ', request);
      },
      (error: any) => {
        // console.log('interceptError: ', error);
      }
    ));
  }
}
