import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpertCardModalComponent } from './expert-card-modal.component';

@NgModule({
  declarations: [
    ExpertCardModalComponent
  ],
  imports: [
    CommonModule
  ],
  entryComponents: [
    ExpertCardModalComponent
  ]
})

export class ExpertCardModalModule { }
