import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()

export class LoadingSpinnerService {

  public loading: BehaviorSubject<boolean> = new BehaviorSubject(false);

  startLoading(): void {
    this.loading.next(true);
  }

  stopLoading(): void {
    this.loading.next(false);
  }

  constructor() {}
}
