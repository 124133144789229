import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpertsBlockComponent } from './experts-block.component';
import { ExpertCardModule } from '../expert-card/expert-card.module';

@NgModule({
  declarations: [ExpertsBlockComponent],
  imports: [
    CommonModule,
    ExpertCardModule
  ],
  exports: [
    ExpertsBlockComponent
  ]
})

export class ExpertsBlockModule { }
