
<div class="notification_main_block">
  <div class="notification_message">
    <mat-icon *ngIf="data.withoutButtons || false">done</mat-icon>
    {{data.message || "Внимание!"}}
  </div>
  <div class="notification_buttons">
    <button mat-button
            *ngIf="data.withInformation && !data.withoutButtons || false"
            (click)="closeWithInfo()">{{data.infoButtonTitle || "ИНФОРМАЦИЯ..."}}</button>
    <button mat-button
            *ngIf="!data.withoutButtons || true"
            (click)='closeNotification()'>{{data.closeButtonTitle || "OK"}}</button>
  </div>
</div>
