import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable, SkipSelf } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class BaseApiService {

  constructor(private http: HttpClient) {}

  private errorFunc(er: any): Observable<never> {
    return  throwError(er);
  }

  get<T>(url: string): Observable<T> {
    return this.http.get<T>(url)
           .pipe(catchError(this.errorFunc));
  }

  getWithParams<T>(url: string,
                   params: HttpParams = new HttpParams(),
                   headers: HttpHeaders = new HttpHeaders()): Observable<T> {
    return this.http.get<T>(url, { params, headers })
      .pipe(catchError(this.errorFunc));
  }

  put<T>(url: string, body: object = {}): Observable<T> {
    return this.http.put<T>(url, JSON.stringify(body))
           .pipe(catchError(this.errorFunc));
  }

  patch<T>(url: string, body: object = {}): Observable<T> {
    return this.http.patch<T>(url, JSON.stringify(body))
           .pipe(catchError(this.errorFunc));
  }

  post<T>(url: string, body: object = {}): Observable<T> {
    return this.http.post<T>(url, JSON.stringify(body))
           .pipe(catchError(this.errorFunc));
  }

  delete(url: string): Observable<any> {
    return this.http.delete(url)
           .pipe(catchError(this.errorFunc));
  }
}
