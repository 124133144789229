import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BaseApiService } from './base-api.service';
import { ModuleWithProviders } from '@angular/compiler/src/core';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ]
})

export class BaseApiModule {

  constructor(@Optional() @SkipSelf() baseApiModule: BaseApiModule) {
    if (baseApiModule) {
      throw new Error(
        'BaseApiModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: BaseApiModule,
      providers: [ BaseApiService ]
    };
  }
}
