import { Component, Input, OnInit } from '@angular/core';
import { IExpertInfo } from '@shared/models';
import { ModalDialogService } from '@shared/services';

@Component({
  selector: 'app-expert-card',
  templateUrl: './expert-card.component.html',
  styleUrls: ['./expert-card.component.scss']
})
export class ExpertCardComponent implements OnInit {

  @Input() item: IExpertInfo = null;

  constructor(private modalService: ModalDialogService) { }

  ngOnInit(): void { }

  showExpertInfo(): void {
    this.modalService.openExpertWindow(this.item);
  }

}
